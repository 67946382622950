<template>
  <div>
    <div style="width: 100%;height:60px"></div>
    <div  v-if="appInfo" class="downFixeMain">
      <div class="downFixeContent">
        <div class="left">
          <div class="name">
            <span> {{ appInfo.siteName || '' }} {{ appInfo.version || '' }}</span>
            <span class="link" @click="toLink('yhxy')">权限</span>|
            <span class="link" @click="toLink('yszc')">隐私</span>|
            <span class="link" @click="show = true">功能</span>
          </div>
          <div class="allName">开发者: {{ appInfo.operatorName || '' }}</div>
        </div>
        <div class="right">
          <div class="btn" @click="clickFn()">立即下载</div>
          <div class="time">{{ appInfo.updateTime ? getTime(appInfo.updateTime) : '' }} 更新</div>
        </div>
      </div>
      <div class="bg_main" v-if="show">
        <div class="mianpopup">
          <div class="title">开发者信息</div>
          <div class="txt">开发者: {{ appInfo.operatorName || '' }}</div>
          <div class="txt">安卓应用版本: {{ appInfo.siteName || '' }} {{ appInfo.version || '' }}</div>
          <div class="txt">更新时间: {{ appInfo.updateTime ? getTime(appInfo.updateTime) : '' }}</div>
          <div class="btn" @click="show = false">确定</div>
        </div>
      </div>
      <div class="bg_main" v-if="show2">
        <div class="iframeMain">
          <iframe v-show="isLoding" class="iframDetail" :src="iframeSrc" @load="iframeLoad"></iframe>
          <div v-show="!isLoding" class="iframDetail"> 加载中...</div>
          <div class="btn" @click="show2 = false;isLoding=false">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bottomPX: {
      type: [Number, String],
      default: 0
    },
    siteId:{
      type: String,
      default: ''
    },
    clickFn:{
      type: Function,
      default: ()=>{}
    }
  },
  data() {
    return {
      iframeSrc:'',
      show2:false,
      isLoding:false,
      show: false,
      appInfo: null
    }
  },
  mounted() {
    fetch('https://lsxb.youpzhaohuo.com/channel/channel/app/baseinfo',{
      method: "GET",
      headers:{
        siteId:this.siteId
      },
    })
    .then(respone => respone.json()) 
    .then(res => {
      console.log(res)
      this.appInfo=res.data
    })
    .catch(console.error);
  },
  methods: {
    iframeLoad(){
      this.isLoding=true
    },
    getTime(value) {
      return new Date(value).toLocaleDateString()
    },
    toLink(type) {
      this.show2=true
      this.iframeSrc=`https://lsxbhd.youpzhaohuo.com/#/agreement/server?treaty=${type}&siteId=${this.siteId}`
    }
  }
}
</script>
<style lang="scss" scoped>
.mianpopup {
  width: 295px;
  background: #ffffff;
  border-radius: 16px;
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .txt {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #333333;
  }
  .btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    color: #ff2220;
    font-size: 14px;
    border-top: 1px solid #f2f2f2;
  }
}
.bg_main{
  background-color: rgba(0, 0, 0, 0.5);
  width:100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iframeMain{
  width: 375px;
  height: 800px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  .iframDetail{
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    cursor: pointer;
    width: 100px;
    height: 30px;
    background-color: #ff2220;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 14px;
    border-radius: 30px;
    margin: 0 auto;
  }
}
.downFixeMain {
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  right: 0;
  bottom:0;
  display: flex;
  justify-content: center;
  .downFixeContent{
    width: 500px;
    height: 60px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 10px;
    .name {
      font-size: 14px;
      span {
        color: #666666;
        &.link {
          text-decoration: underline;
          margin: 0 5px;
          color: #44b0e4;
          cursor: pointer;
        }
      }
    }
    .allName {
      font-size: 14px;
      color: #666666;
      margin-top: 8px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 10px;
    .btn {
      cursor: pointer;
      width: 100px;
      height: 30px;
      background-color: #ff2220;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      border-radius: 30px;
    }
    .time {
      margin-top: 5px;
      font-size: 12px;
      color: #666663;
    }
  }
}
::v-deep .van-overlay {
  z-index: 99999 !important;
}
::v-deep .van-popup {
  background-color: rgba(0, 0, 0, 0) !important;
}
::v-deep .van-popup--center {
  z-index: 199999 !important;
}
</style>
